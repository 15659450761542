html,
body {
  max-width: 100vw;
  height: 100vh;
}

.bx-page {
  height: calc(100% - 72px);
  background-color: #0b2631;
  color: aliceblue;
}

/* styles/global.css */
._hj-widget-container {
  height: 0 !important;
}

.mapbox-tooltip-container,
.mapbox-tooltip-container > div {
  height: 0%;
}

/* Hide Usetiful Awesome Extension */
#ExtensionCheck_UsetifulAwesomeExtension {
  display: none;
}
